import React, { useEffect, useState } from "react";
import logo from "../../images/logo.svg";
import logoutImage from "../../images/logout16.png";
import "../../simplelocker.css";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../Redux/Authentication";
import { toast } from "react-toastify";
import Video from "../../images/video.png";
import {
  checkFileExists,
  formattedDateTime1,
  formattedDateTime,
} from "../../utils/generic";
import { io } from "socket.io-client";

const imageUrl = process.env.REACT_APP_APP_IMAGE_URL;
const url = process.env.REACT_APP_APP_BACK_URL;
const socketUrl = process.env.REACT_APP_APP_SOCKET_URL;
const socket = io.connect(socketUrl);
let idleTimer;

const Header = ({ onIconClick }) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const isAuthenticated = useSelector((state) => state.user);
  let { slug } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [videoShow, setVideoShow] = useState(false);
  const [stream, setStream] = useState();
  const [getSite, setSite] = useState({});
  const [siteLogo, setSiteLogo] = useState(imageUrl + "/locker_logo.png");
  const idleTimeoutInSeconds = isAuthenticated ? isAuthenticated.idle_time : 0; // 5 minutes (adjust as needed)

  const startChat = () => {
    console.log("Start Chat");
    console.log(slug);
    // socket.emit("user_login", slug);
    socket.emit("sendNotification", slug);
    navigate(`/${slug}/videochat`);
  };

  // async function setStreamFun() {
  //   try {
  //     const stream = await navigator.mediaDevices.getUserMedia({
  //       audio: false,
  //       video: false,
  //     });

  //     const audioTrack = stream.getAudioTracks()[0];

  //     // Mute the audio track by default
  //     audioTrack.enabled = true;
  //     const videoTrack = stream.getVideoTracks()[0];
  //     setStream(stream);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // }
  // function stopCamera() {
  //   if (stream) {
  //     // Stop all tracks in the stream
  //     stream.getTracks().forEach((track) => track.stop());

  //     // Optional: If you want to clear the stream object
  //     // Note: This is not always necessary, depending on your use case
  //     setStream(null);
  //   }
  // }
  const resetIdleTimer = () => {
    clearTimeout(idleTimer);
    idleTimer = setTimeout(handle_logout, idleTimeoutInSeconds * 1000);
  };
  const handle_logout = async (e) => {
    if (getSite.required_pin) {
      localStorage.removeItem("token");
      navigate(`/${slug}/pin`);
      dispatch(logout());
    }
  };

  useEffect(() => {
    siteData();
  
    if (isAuthenticated && isAuthenticated.role !== "super admin") {
      resetIdleTimer();
      const activityEvents = ["mousemove", "keydown", "mousedown", "scroll"];
      const onActivity = () => {
        resetIdleTimer();
      };
      activityEvents.forEach((event) => {
        window.addEventListener(event, onActivity);
      });
      return () => {
        activityEvents.forEach((event) => {
          window.removeEventListener(event, onActivity);
        });
      };
    }
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);
    return () => {
      clearInterval(intervalId);
      // stopCamera();
    };
  }, []);
  useEffect(() => { }, []);

  // get all site
  const siteData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/sites/getSite/${slug}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      if (json.success) {
        setSite(json.site);
        console.log(json.site);
        const isExist = await checkFileExists(json?.site?.image);
        setSiteLogo(json.site.image);
      } else {
        toast.error("No site found! Enter valid site");
      }
    } catch (error) {
      console.error(error);
    }
  };
  console.log(siteLogo, "siteLogo");
  return (
    <div>
      <header className="header">
        <div className="row mb-2">
          <div className="prof-sett">
            <div className="col-4 d-flex justify-content-start">
              <div className="main-heading">
                <div className="locker-logo">
                  <div className="locker-style-image d-flex justify-content-center">
                    <img
                      className="logo-image-style img-fluid mx-auto "
                      src={siteLogo}
                      alt="Lockers"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 d-flex justify-content-center ">
              <div
                className="d-flex gap-2 justify-content-center align-items-center flex-row"
                onClick={onIconClick}
              >
                <img
                  src={Video}
                  alt="video"
                  height={50}
                  width={50}
                  style={{ cursor: "pointer" }}
                />
                <span

                  style={{
                    fontSize: "14px",
                    fontWeight: "700",
                    color: "#012269",
                  }}
                >
                  Video <br />
                  Attendant
                </span>
              </div>
            </div>

            <div className="col-4 d-flex justify-content-center px-4">
              <div className="locker-timer">
                <p>{formattedDateTime(currentDateTime)}</p>
                <p>{formattedDateTime1(currentDateTime)}</p>
                {isAuthenticated && (
                  <Link
                    to={`/${slug}/admin/pin`}
                    className="bg-white px-3 shadow-sm"
                    style={{
                      border: "1px solid #012267",
                      borderRadius: "4px",
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    Admin
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
export default Header;
